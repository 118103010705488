import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { string, func, bool } from 'prop-types';

import { useConfiguration } from '../../context/configurationContext';

import { LISTING_TYPE_BUSINESS, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';
import { boothTypes } from '../../config/configListing';
import { displayPrice } from '../../util/configHelpers';
import { ensureListing, ensureUser } from '../../util/data';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    handleHover,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { attributes, profileImage } = currentListing.author || {};
  const { rfpBids = 0, acceptedBids = 0, ratings = 0, totalRatings = 0 } = attributes.profile.publicData || {};
  const { title, price, createdAt, publicData } = currentListing.attributes;
  const {
    city,
    region,
    postcode,
    dbaName,
    address1,
    boothType,
    companyName,
    typeOfListing
  } = publicData || {};
  const getBoothFilter = boothTypes.filter((st) => boothType && boothType.length && boothType.includes(st.key));
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage = profileImage
    ? profileImage
    : currentListing.images && currentListing.images.length > 0
      ? currentListing.images[0]
      : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;
  const authorId = listing?.author?.id?.uuid;
  const isBusiness = typeOfListing == LISTING_TYPE_BUSINESS && authorId;

  return (
    <NamedLink className={classes} name={isBusiness ? "ProfilePage" : "ListingPage"} params={{ id: isBusiness ? authorId : id, slug }}>
      <div {...setActivePropsMaybe} onMouseOver={() => handleHover(id)} onMouseOut={() => handleHover(id, false)}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
        <div className={css.info}>
          <h3 className={css.title} onClick={(e) => {
            e.preventDefault();
            if (authorId) {
              return window.open(`${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/u/${authorId}`, "_self")
            }
          }}>{companyName || title} ({dbaName})</h3>
          {city && region
            ? <p className={css.details}>
              <span>City: </span>{city + ', ' + region}
            </p>
            : null}
          {getBoothFilter && getBoothFilter.length
            ? <p className={css.booths}>
              Booths: {getBoothFilter.map((st) => st.label).join(", ")}
            </p>
            : null}
          <p className={css.details}>
            <span>Rating: </span>{ratings <= 1 ? ratings + ' Star' : '5 Stars'} ({totalRatings > 1 ? (totalRatings + ' Reviews') : (totalRatings + ' Review')})
          </p>
          <p className={css.details}>
            <span>RFPs Created: </span>{rfpBids}
          </p>
          <p className={css.details}>
            <span>RFPs Bid On: </span>{acceptedBids}
          </p>
          <p className={css.details}>
            <span>Member Since: </span>
            {moment(createdAt).format('MMM D/YY')}
          </p>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
