import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

const AlgoliaAutocomplete = (props) => {
    const history = useHistory();
    const routes = useRouteConfiguration();

    const { className, initialValues } = props;
    const [searchTerm, setsearchTerm] = useState(initialValues || "");

    // &query
    const onSubmit = () => {
        return history.push(createResourceLocatorString('SearchPage', routes, {}, { keywords: searchTerm }));
    }

    return  <input
        value={searchTerm}
        type="text"
        className={className}
        placeholder="Search by keyword, location or business name..."
        onChange={(e) => {
            return setsearchTerm(e.currentTarget.value);
        }}
        onBlur={(e) => {
            e.preventDefault();
            return onSubmit();
        }}
        onKeyUpCapture={(e) => {
            e.preventDefault();
            if (e.key == "Enter") {
                return onSubmit();
            }
        }}
    /> 

};

export default withRouter(AlgoliaAutocomplete);
